import {defineStore} from "pinia";
import cacheget, {cacheclean} from "@/utils/fnCacheUtil";
import _ from "lodash";
import {role} from "@/api/_request";
import * as accountApi from "@/api/account";
// import {useRoute} from "vue-router";

export default defineStore("accountInfo", {
    // TODO 需要重构当前模块
    "state": () => ({
        "roleList": null,
        "schoolClassList": [],
        "schoolList": [],
    }),
    "getters": {
        roleName() {
            // TODO 错误写法，
            // const route = useRoute();
            // return route?.params.role;
            return role.toString();
        },
        role() {
            return this.roleList?.find(r => r.role === this.roleName) || {};
        },
        roleMap() {
            return _.keyBy(this.roleList, "role");
        },
        classList() {
            if (this.roleName === "家长") {
                return this.roleMap["家长"]?.classList;
            } else {
                return this.schoolClassList;
            }
        },
        classMap() {
            return _.keyBy(this.classList, "classId");
        },
        gradeList() {
            return _.map(this.classList, "grade");
        },
    },
    "actions": {
        getAccountInfo() {
            return cacheget("accountInfo", async () => {
                const {data} = await accountApi.getAccountInfo();
                this.roleList = data?.roleList || [];
                return data;
            });
        },
        async loginXZQT(token) {
            cacheclean("accountInfo");
            const {data} = await accountApi.loginXZQT(token);
            this.roleList = data?.roleList || [];
            return data;
        },
        async loginDingtalk(authCode, corpId) {
            cacheclean("accountInfo");
            const resp = await accountApi.loginDingtalk(authCode, corpId);
            this.roleList = resp.data?.roleList || [];
            return resp;
        },
    },
});
