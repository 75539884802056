/**
 * 避免重复重复请求的函数
 * @param {*} fn
 * @returns
 */

const cache = {};
export default function cacheget(key, fn) {
    if (cache[key]) return cache[key];
    cache[key] = fn();
    return cache[key];
}

export function cacheclean(key) {
    cache[key] = null;
}

export function cacheset(key, value) {
    cache[key] = value;
}
