import request from "./_request";

export function loginXZQT(token) {
    return request.post("/api/account/login/xzqt", {token});
}

export function loginDingtalk(code, corpId) {
    return request.get("/api/account/login/dingtalk", {"params": {code, corpId}});
}

export function logout() {
    return request.get("/api/account/logout");
}

export function getAccountInfo() {
    return request.get("/api/account/info");
}
